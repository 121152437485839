// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import GSuiteIntegration from "../../blocks/GSuiteIntegration/src/GSuiteIntegration";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import PosIntegration from "../../blocks/PosIntegration/src/PosIntegration";
import BarcodesScanner from "../../blocks/BarcodesScanner/src/BarcodesScanner";
import CfCustomisableBills from "../../blocks/CfCustomisableBills/src/CfCustomisableBills";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import InventoryTrendAnalysis from "../../blocks/InventoryTrendAnalysis/src/InventoryTrendAnalysis";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import TaxCalculator from "../../blocks/TaxCalculator/src/TaxCalculator";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import OrderCreation6 from "../../blocks/OrderCreation6/src/OrderCreation6";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import CfIntegrationWithCpPlus from "../../blocks/CfIntegrationWithCpPlus/src/CfIntegrationWithCpPlus";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Notes from "../../blocks/Notes/src/Notes";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import CfRazorpayIntegration8 from "../../blocks/CfRazorpayIntegration8/src/CfRazorpayIntegration8";
import Notifications from "../../blocks/notifications/src/Notifications";
import RealtimeUpdates from "../../blocks/RealtimeUpdates/src/RealtimeUpdates";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import OnlineBackup from "../../blocks/OnlineBackup/src/OnlineBackup";
import CfCreateACreditNoteAndDebitNote from "../../blocks/CfCreateACreditNoteAndDebitNote/src/CfCreateACreditNoteAndDebitNote";
import BaselineReporting from "../../blocks/BaselineReporting/src/BaselineReporting";
import CfWatiWhatsappBotIntegration from "../../blocks/CfWatiWhatsappBotIntegration/src/CfWatiWhatsappBotIntegration";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CfCreateACustomPicklistToPickMedicines from "../../blocks/CfCreateACustomPicklistToPickMedicines/src/CfCreateACustomPicklistToPickMedicines";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Prioritise from "../../blocks/Prioritise/src/Prioritise";
import BarcodeSettings from "../../blocks/BarcodeSettings/src/BarcodeSettings";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DownloadOptions from "../../blocks/DownloadOptions/src/DownloadOptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import CfEcoGreenIntegration from "../../blocks/CfEcoGreenIntegration/src/CfEcoGreenIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import SalesReporting from "../../blocks/SalesReporting/src/SalesReporting";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
GSuiteIntegration:{
 component:GSuiteIntegration,
path:"/GSuiteIntegration"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
PosIntegration:{
 component:PosIntegration,
path:"/PosIntegration"},
BarcodesScanner:{
 component:BarcodesScanner,
path:"/BarcodesScanner"},
CfCustomisableBills:{
 component:CfCustomisableBills,
path:"/CfCustomisableBills"},
BreadcrumbNavigation:{
 component:BreadcrumbNavigation,
path:"/BreadcrumbNavigation"},
InventoryTrendAnalysis:{
 component:InventoryTrendAnalysis,
path:"/InventoryTrendAnalysis"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
TaxCalculator:{
 component:TaxCalculator,
path:"/TaxCalculator"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
OrderCreation6:{
 component:OrderCreation6,
path:"/OrderCreation6"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
CfIntegrationWithCpPlus:{
 component:CfIntegrationWithCpPlus,
path:"/CfIntegrationWithCpPlus"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Notes:{
 component:Notes,
path:"/Notes"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
CfRazorpayIntegration8:{
 component:CfRazorpayIntegration8,
path:"/CfRazorpayIntegration8"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
RealtimeUpdates:{
 component:RealtimeUpdates,
path:"/RealtimeUpdates"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
OnlineBackup:{
 component:OnlineBackup,
path:"/OnlineBackup"},
CfCreateACreditNoteAndDebitNote:{
 component:CfCreateACreditNoteAndDebitNote,
path:"/CfCreateACreditNoteAndDebitNote"},
BaselineReporting:{
 component:BaselineReporting,
path:"/BaselineReporting"},
CfWatiWhatsappBotIntegration:{
 component:CfWatiWhatsappBotIntegration,
path:"/CfWatiWhatsappBotIntegration"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
CfCreateACustomPicklistToPickMedicines:{
 component:CfCreateACustomPicklistToPickMedicines,
path:"/CfCreateACustomPicklistToPickMedicines"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Prioritise:{
 component:Prioritise,
path:"/Prioritise"},
BarcodeSettings:{
 component:BarcodeSettings,
path:"/BarcodeSettings"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
CfEcoGreenIntegration:{
 component:CfEcoGreenIntegration,
path:"/CfEcoGreenIntegration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
SalesReporting:{
 component:SalesReporting,
path:"/SalesReporting"},

  Home: {
component:VisualAnalytics,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
